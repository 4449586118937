.imgSlide {
  -webkit-user-drag: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.map_wrap {
  position: relative;
  /* border: 10px solid red; */
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
}
.map_container {
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: 0% 0%;
  touch-action: none;
  cursor: grab;
  -webkit-user-drag: none;
  transition: all 0.1s ease;
}
.map_container > img {
  width: 100%;
  height: auto;
  display: block;
  cursor: grab;
  position: absolute;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.map_container > svg {
  width: 100%;
  position: absolute;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
