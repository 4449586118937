@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

/* SCROLL BAR */

::-webkit-scrollbar {
  width: 6px; /* Độ rộng ngang của thanh cuộn */
  height: 4px; /* Độ rộng của dọc thanh cuộn */
  background-color: transparent; /* Màu nền của thanh cuộn */
}

/* Tùy chỉnh slider của thanh cuộn */
::-webkit-scrollbar-thumb {
  background-color: rgb(252, 238, 163); /* Màu của slider */
  border-radius: 4px; /* Bo góc cho slider */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: 0.8;
}

iframe {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.titleUtil {
  font-family: "Playfair Display", serif;
  font-size: 36px;
}

.logoText {
  font-family: "Playfair Display", serif;
  user-select: none;
  -webkit-user-select: none;
}

/* SVG RUN ANIMATION */
.location_map_svg {
  path {
    stroke: #fbb91a;
    stroke-dasharray: 8, 10;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dashoffset: 82;
  }
}

.map_svg_active {
  path {
    fill-opacity: 10;
    stroke: #FBE67B;
    stroke-miterlimit: 10;
    stroke-width: 4;
    stroke-dasharray: 8, 10;
    /* // stroke-dasharray: 2000; */
    stroke-dashoffset: 150;
  }

  path:nth-child(odd) {
    animation: pathMove 2s infinite linear;
  }
  
  path:nth-child(even) {
    animation: pathMove 2s infinite linear;
  }
/* 
  circle {
    stroke: #FBE67B;
  } */
}

@keyframes pathMove {
  to {
    stroke-dashoffset: 10;
  }
}

.svg_location {
  transition: all 0.3s ease;
  animation: fadeIn 0.7s linear;
  /* animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite; */
}

@keyframes pulse {
  50% {
      opacity: .5;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
    transform: translateY(0)
  }
  40%{
    opacity: 0.6;
    transform: translateY(-30px)
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

